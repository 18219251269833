import React, { useState, useEffect } from 'react';
import * as Realm from "realm-web"; // Import Realm Web SDK
import { Helmet } from "react-helmet"; // Import Helmet
import bunningsLogo from './logo.png';
import microphoneButton from './mic_640p.png';
import microphoneButtonActive from './mic_640p_green.png';
import './App.css';

// Helper function to calculate distance
const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the Earth in km
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a = 
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in km
    return distance;
};

const deg2rad = (deg) => {
    return deg * (Math.PI / 180);
};

// Function to convert string to title case
const toTitleCase = (str) => {
    return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
};

function App() {
    const [location, setLocation] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResult, setSearchResult] = useState('');
    const [closestStore, setClosestStore] = useState('');
    const [postcode, setPostcode] = useState(''); // Add state for postcode
    const [recognizing, setRecognizing] = useState(false);
    const [recognition, setRecognition] = useState(null);
    const [locationError, setLocationError] = useState(null); // Add state for location error
    const [micButtonPressed, setMicButtonPressed] = useState(false); // Add state for mic button pressed
    const [hasMicBeenActivated, setHasMicBeenActivated] = useState(false); // New state to track if the mic was ever activated

    useEffect(() => {
        getLocation();
        initializeRecognition();
        preloadImages();  // Preload the images
    }, []);

    useEffect(() => {
        if (searchQuery) {
            performSearch(searchQuery);
        }
    }, [searchQuery]);

    const preloadImages = () => {
        const images = [microphoneButton, microphoneButtonActive];
        images.forEach((image) => {
            const img = new Image();
            img.src = image;
        });
    };

    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    console.log("Latitude: ", latitude, "Longitude: ", longitude);
                    setLocation({ latitude, longitude });
                    setLocationError(null); // Clear any previous error
                    fetchClosestStore(latitude, longitude);
                },
                (error) => {
                    console.error("Error obtaining location: ", error);
                    if (error.code === error.PERMISSION_DENIED) {
                        setLocationError("Location access denied. Please enable location services in your device settings.");
                    } else {
                        setLocationError(`Error obtaining location: ${error.message}`);
                    }
                },
                {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 0
                }
            );
        } else {
            setLocationError('Geolocation is not supported by this browser.');
        }
    };

    const fetchClosestStore = async (latitude, longitude) => {
        const query = 'Bunnings Warehouse';
        const radius = 8000; // 8 km radius

        // Convert radius from meters to degrees
        const radiusInDegrees = radius / 111320;

        const minLat = latitude - radiusInDegrees;
        const maxLat = latitude + radiusInDegrees;
        const minLon = longitude - radiusInDegrees / Math.cos(latitude * Math.PI / 180);
        const maxLon = longitude + radiusInDegrees / Math.cos(latitude * Math.PI / 180);

        try {
            const response = await fetch(
                `https://nominatim.openstreetmap.org/search.php?q=${query}&format=json&limit=50&bounded=1&viewbox=${minLon},${maxLat},${maxLon},${minLat}`
            );
            const data = await response.json();
            console.log("API Response: ", data); // Debugging line
        
            if (data && data.length > 0) {
                // Filter out "Future Bunnings store"
                const validStores = data.filter(store => !store.display_name.includes('Future'));
        
                if (validStores.length === 0) {
                    setClosestStore('No store found nearby');
                    return;
                }
        
                const closestStore = validStores.reduce((prev, curr) => {
                    const prevDistance = getDistanceFromLatLonInKm(latitude, longitude, prev.lat, prev.lon);
                    const currDistance = getDistanceFromLatLonInKm(latitude, longitude, curr.lat, curr.lon);
                    return (prevDistance < currDistance) ? prev : curr;
                });
        
                console.log("Closest Store: ", closestStore); // Debugging line
        
                // Extract suburb and postcode
                const displayParts = closestStore.display_name.split(',');
                let suburb;
                let postcode = displayParts[displayParts.length - 2].trim(); // Extract the second last part as postcode
                suburb = displayParts[displayParts.length - 6].trim();
        
                console.log("Display Location: ", suburb); // Debugging line
        
                setClosestStore(suburb);
                setPostcode(postcode); // Set the postcode state
            } else {
                setClosestStore('No store found nearby');
            }
        } catch (error) {
            console.error('Error fetching closest store:', error);
            setClosestStore('Error fetching closest store');
        }
    };

    const initializeRecognition = () => {
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        const recognitionInstance = new SpeechRecognition();
        recognitionInstance.continuous = false;
        recognitionInstance.interimResults = false;
        recognitionInstance.onstart = () => {
            setRecognizing(true);
            setMicButtonPressed(true);
            setHasMicBeenActivated(true); // Set to true when mic is first activated
        };
        recognitionInstance.onend = () => {
            setRecognizing(false);
            setMicButtonPressed(false);
        };
        recognitionInstance.onresult = (event) => {
            const transcript = event.results[0][0].transcript.toLowerCase();
            setSearchQuery(transcript);
        };
        setRecognition(recognitionInstance);
    };

    const handleMicClick = () => {
        if (!recognition) {
            initializeRecognition();
        }
        if (recognizing) {
            recognition.stop();
        } else {
            recognition.start();
            setMicButtonPressed(true);
        }
    };

    const performSearch = async (query) => {
        const app = new Realm.App({ id: "data-ttoafpk" });
        const credentials = Realm.Credentials.anonymous();
        try {
            const user = await app.logIn(credentials);
            const functionResult = await user.functions.getItem(query, postcode);
            console.log("Function Result: ", functionResult); // Debugging line
    
            if (functionResult.error) {
                setSearchResult(functionResult.error);
            } else if (functionResult.length === 0) {
                setSearchResult("Ask Team Member");
            } else {
                const results = functionResult.map((item, index) => {
                    console.log(`Result ${index}: Name=${item.name}, Aisle=${item.aisle}, Bay=${item.bay}`); // Debugging each result
                    if (item.aisle === 0 && item.bay === 0) {
                        return `<strong>${item.name}:</strong> Trade & Garden Area`;
                    } else {
                        return `<strong>${item.name}</strong>, Aisle: ${item.aisle}, Bay: ${item.bay}`;
                    }
                });
                console.log("Processed Results: ", results); // Debugging line
                setSearchResult(results);
            }
        } catch (err) {
            console.error("Ask Team Member", err);
            setSearchResult("Ask Team Member");
        }
    };
    
    return (
        <div className="App">
            <Helmet>
                <title>Item Locator - Find Products Easily</title>
                <meta name="description" content="Locate items quickly in your nearby Bunnings store with our Item Locator." />
                <meta name="keywords" content="item locator, Bunnings, product search, store locator" />
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "http://schema.org",
                        "@type": "WebSite",
                        "name": "Item Locator",
                        "url": "https://www.bunningslocator.com",
                    })}
                </script>
            </Helmet>
            <header className="App-header">
                <img src={bunningsLogo} className="App-logo" alt="Bunnings logo" />
                <h1>Item Locator</h1>
                {closestStore && (
                    <p>
                        Bunnings: {toTitleCase(closestStore)}, {postcode}
                    </p>
                )}
                <div className="mic-container">
                    <button 
                        className={`mic-button ${recognizing ? 'active' : hasMicBeenActivated ? '' : 'pulse'}`} 
                        onClick={handleMicClick}
                    >
                        <img 
                            src={recognizing ? microphoneButtonActive : microphoneButton} 
                            alt="Microphone button" 
                        />
                        {recognizing && <div className="listening-label">Listening...</div>}
                    </button>
                </div>
                <input 
                    type="text" 
                    className="search-box" 
                    placeholder="Search for an item"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value.toLowerCase())} // Convert to lowercase
                />
                {Array.isArray(searchResult) ? (
                    searchResult.map((result, index) => (
                        <p key={index} className="result-item" dangerouslySetInnerHTML={{__html: result}}></p>
                    ))
                ) : (
                    <p>{searchResult}</p>
                )}
                {locationError && (
                    <p className="error-message">
                        {locationError}
                    </p>
                )}
            </header>
            <footer className="App-footer">
                <button className="link-button" onClick={() => window.open('https://www.coleslocator.com', '_blank')}>
                    Go to Coles Locator
                </button>
                <p className="footer-text-small">Powered by OpenAI semantic search</p>
                <p className="footer-text-small">Purely non-commercial, not endorsed by Coles Group. Dev: A.Perfrement 2024</p>
            </footer>
        </div>
    );
}

export default App;
